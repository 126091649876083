/* eslint-disable no-console, no-unused-vars */

import "./App.css";
import React from "react";

// import "react-toastify/dist/ReactToastify.css";
import ComingSoon from "./ComingSoon.js";

// Code with disabled linting rules
console.log("This will not trigger ESLint errors for no-console");

function App() {
  const unusedVar = "This might trigger a no-unused-vars warning";

  return (
    <div className="App">
      {/* <ToastContainer />
      <PortfolioContainer /> */}
      <ComingSoon />
    </div>
  );
}

export default App;
/* eslint-enable no-console, no-unused-vars */
